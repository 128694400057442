








































import { UnionServices } from '@/models/IUserUses'
import { PropType } from 'vue'
import facebookPage from '@/assets/img/facebook-logo-gray.svg'
import facebookBtn from '@/assets/img/facebook-logo-white.svg'

const serviceIcons: Record<UnionServices, Record<'btn' | 'page', string>> = {
  facebook: {
    page: facebookPage,
    btn: facebookBtn
  },
  snapchat: {
    page: facebookPage,
    btn: facebookBtn
  },
  tiktok: {
    page: facebookPage,
    btn: facebookBtn
  },
  linkedin: {
    page: facebookPage,
    btn: facebookBtn
  },
  google: {
    page: facebookPage,
    btn: facebookBtn
  },
  twitter: {
    page: facebookPage,
    btn: facebookBtn
  }
}

interface Data {
  serviceIcons: typeof serviceIcons
}

export default {
  name: 'PostBoostingWithoutConnect',
  data: (): Data => ({ serviceIcons }),
  props: {
    service: {
      type: String as PropType<UnionServices>,
      required: true
    }
  },
  computed: {
    pageIcon (): string {
      return this.serviceIcons[this.service].page
    },
    btnIcon (): string {
      return this.serviceIcons[this.service].btn
    },
    btnClass (): string {
      return `connect__btn_${this.service}`
    },
    capitalService (): string {
      if (this.service === 'tiktok') {
        return 'TikTok'
      }
      if (this.service === 'linkedin') {
        return 'LinkedIn'
      }
      return `${this.service[0]}${this.service.slice(1)}`
    }
  },
  methods: {
    routerToIntegrations (): void {
      this.$router.push({ name: 'Integrations' })
    }
  }
}

